<template>
  <b-modal
      id="confirm-refund-adyen"
      :title="$t('Confirm refund in Adyen')"
      size="xl"
      @ok="refundAdyen"
      @shown="shownRefundAdyenModal"
      :ok-disabled="Array.isArray(list.error)"
  >
    <template #modal-ok>
      <b-spinner small :class="{'spinner-border mx-2': 1, 'd-none': !refundAdyenSending}"></b-spinner>
      <span :class="{'d-none': refundAdyenSending}">{{ $t('Send') }}</span>
    </template>
    <template #modal-cancel>{{ $t('Close') }}</template>
    <b-overlay :show="refundAdyenSending">
      <b-alert variant="danger" :show="Array.isArray(list.error)">
        <div v-for="(error, i) in list.error" :key="i">{{ error }}</div>
      </b-alert>
      <b-table-simple small responsive>
        <b-thead>
          <b-tr>
            <b-th>{{ $t('Sku')}} ({{ $t('Size')}}) {{ $t('Ean') }}</b-th>
            <b-th>{{ $t('Resolution')}}</b-th>
            <b-th>{{ $t('Operation')}}</b-th>
            <b-th>{{ $t('Status')}}</b-th>
            <b-th>{{ $t('Adyen Status')}}</b-th>
            <b-th>{{ $t('Adyen Status Updated At')}}</b-th>
            <b-th>{{ $t('Approved Amount')}}</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(product) in list.products" :key="product.id" >
            <b-td><span class="text-nowrap">{{ product.sku }} ({{ product.size }})</span> <br> {{ product.ean }}</b-td>
            <b-td>{{ $t(product.resolution) }}</b-td>
            <b-td>{{ $t(product.operation) }}</b-td>
            <b-td>{{ $t(product.status) }}</b-td>
            <b-td>{{ product.adyenStatus }}</b-td>
            <b-td>{{ product.adyenCreatedAt }}</b-td>
            <b-td>{{ list.currency }} {{ product.approvedAmount.toFixed(2) }}</b-td>
          </b-tr>
        </b-tbody>
        <b-tfoot>
          <b-tr>
            <b-th colspan="6">{{ $t('Reference') }}: {{ list.reference }}</b-th>
            <b-th>{{ list.currency }} {{ list.amount.toFixed(2) }} ({{ list.minorUnitAmount }})</b-th>
          </b-tr>
        </b-tfoot>
      </b-table-simple>
    </b-overlay>
  </b-modal>
</template>

<script>
import {postJsonData} from "@/services/fetchApi";

export default {
  name: "RefundAdyenModal",
  props: {
    value: {
      type: Array,
      required: true
    },
    selected: {
      type: Array,
      required: true
    },
    rmaNumber: {
      type: String,
      required: true
    },
    currency: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      refundAdyenSending: false,
      refundAdyenErrors: null,
      list: {
        products: [],
        currency: null,
        reference: null,
        amount: 0.00,
        minorUnitAmount: 0,
        error: null
      },
      model: {
        productsIds: []
      }
    }
  },
  mounted() {
    this.initModel();
  },
  methods: {
    initModel() {
      const $this = this;
      $this.model.productsIds = $this.selected;
    },
    shownRefundAdyenModal() {
      const $this = this;
      $this.refundAdyenErrors = null;
      $this.list = {
        products: [],
        currency: null,
        reference: null,
        amount: 0.00,
        minorUnitAmount: 0,
        error: null
      };
      $this.refundAdyenSending = true;
      $this.initModel();
      postJsonData(process.env.VUE_APP_BACKEND_SERVER + 'api/refunds/' + this.rmaNumber + '/list', JSON.stringify($this.model))
          .then(json => {
            $this.list = json;
          })
          .catch(function(error) {
            let message = error;
            $this.refundAdyenErrors = [message];
            $this.$store.commit('showToast', {
              message: message,
              title: $this.$t('Adyen'),
              type: 'danger'
            });
          })
          .finally(() => {
            $this.refundAdyenSending = false;
            $this.$store.commit('setLoadingAnything', false);
          });
    },
    refundAdyen(e) {
      e.preventDefault();
      const $this = this;
      if ($this.refundAdyenSending) {
        return false;
      }
      $this.refundAdyenSending = true;
      $this.$store.commit('setLoadingAnything', true);
      $this.refundAdyenErrors = null;
      postJsonData(
          process.env.VUE_APP_BACKEND_SERVER + 'api/refunds/' + this.rmaNumber + '/adyen',
          JSON.stringify($this.model),
      )
          .then(json => {
            $this.list = json;
          })
          .catch(error => {
            let message = $this.$t('Can not process refund in Adyen') + ':' + (error ?? $this.$t('Unknown error'));
            $this.$store.commit('showToast', {
              message: message,
              title: $this.$t('Adyen'),
              type: 'danger'
            });
            $this.refundAdyenErrors = [message];
          })
          .finally(() => {
            $this.refundAdyenSending = false;
            $this.$store.commit('setLoadingAnything', false);
          });
      return false;
    },
  },
  watch: {
    value() {
      this.initModel();
    },
    selected() {
      this.initModel();
    }
  }
}
</script>

<style scoped>

</style>